<template>
    <div class="arp">
        <div class="header1 page-header">
            <h1 class="title">売掛／買掛</h1>
            <div style="display:inline-block; border-right:1px solid #999;">
                <OpRadioGroup
                    v-model="listdiv"
                    v-slot="{ item }"
                    :choices="[
                        { key: 's', name: '売掛' },
                        { key: 'p', name: '買掛' }
                    ]"
                    :itemKey="item => item.key"
                    required
                    hideheader
                >
                    {{ item.name }}
                </OpRadioGroup>
            </div>
            <div style="display:inline-block;">
                <OpRadioGroup
                    v-model="funcdiv"
                    v-slot="{ item }"
                    :choices="[
                        { key: 'o', name: '計上' },
                        { key: 'd', name: listdiv == 's' ? '回収' : '支払' }
                    ]"
                    :itemKey="item => item.key"
                    required
                    hideheader
                >
                    {{ item.name }}
                </OpRadioGroup>
            </div>
            <div style="display:inline-block;">
                <OpSelectFiscalyear
                    v-model="fiscalyear"
                    label="会計年度"
                    @input="changedYear"
                    :company="getCompany()"
                    required
                    hideheader
                />
            </div>
            <div>
                <OpMultiSelector
                    v-model="dpstatus"
                    v-slot="{ item }"
                    :list="dpstatuslist"
                    :itemKey="item => item.key"
                >
                    {{ item.name }}
                </OpMultiSelector>
            </div>
            <button @click="download">
                <IconBase
                    iconName="Excel"
                    width="24"
                    height="24"
                    visibleName
                    v-slot="{ viewbox }"
                    ><IconDownload @viewbox="viewbox"
                /></IconBase>
            </button>

            <div style="display:block; width:100%;">
                <div v-if="funcdiv == 'o' && listdiv == 's'" class="anchors">
                    <a
                        v-for="(d, ix) in stspattern"
                        :key="ix"
                        :href="'#os' + ix"
                        v-smooth-scroll="{ offset: -68 }"
                        class="tableanch"
                    >
                        {{ formatlink(d) }}
                    </a>
                </div>
                <div v-if="funcdiv == 'o' && listdiv == 'p'" class="anchors">
                    <div
                        v-for="(d, ix) in stspattern"
                        :key="ix"
                        style="display:inline-block; padding: 0 10px;"
                    >
                        {{ formatlink(d) }} :
                        <a
                            v-for="(g, gx) in ['1', '2', '3']"
                            :key="gx"
                            :href="'#op' + ix + '-' + gx"
                            v-smooth-scroll="{ offset: -68 }"
                            class="tableanch"
                        >
                            {{ getPodivName(g) }}
                        </a>
                    </div>
                </div>
                <div v-if="funcdiv == 'd' && listdiv == 's'" class="anchors">
                    <a
                        v-for="(d, ix) in stspattern"
                        :key="ix"
                        :href="'#ds' + ix"
                        v-smooth-scroll="{ offset: -68 }"
                        class="tableanch"
                    >
                        {{ formatlink(d) }}
                    </a>
                </div>
                <div v-if="funcdiv == 'd' && listdiv == 'p'" class="anchors">
                    <a
                        v-for="(d, ix) in stspattern"
                        :key="ix"
                        :href="'#dp' + ix"
                        v-smooth-scroll="{ offset: -68 }"
                        class="tableanch"
                    >
                        {{ formatlink(d) }}
                    </a>
                </div>
            </div>
        </div>

        <div class="body" v-if="funcdiv == 'o' && listdiv == 's'">
            <div
                class="tableframe"
                v-for="(d, ix) in stspattern"
                :key="ix"
                :id="'os' + ix"
            >
                <AreceivableTable
                    listdiv="acceptance"
                    :caption="formatCaption(d)"
                    :fiscalyear="fiscalyear.key"
                    :refreshcount="refreshCount"
                    :orderstatus="d"
                />
            </div>
        </div>
        <div class="body" v-if="funcdiv == 'o' && listdiv == 'p'">
            <div v-for="(d, ix) in stspattern" :key="ix">
                <div
                    class="tableframe"
                    v-for="(g, gx) in ['1', '2', '3']"
                    :key="gx"
                    :id="'op' + ix + '-' + gx"
                >
                    <AreceivableTable
                        listdiv="delivery"
                        :caption="formatCaption(d, g)"
                        :fiscalyear="fiscalyear.key"
                        :refreshcount="refreshCount"
                        :params="{ podiv: g }"
                        :orderstatus="d"
                    />
                </div>
            </div>
        </div>
        <div class="body" v-if="funcdiv == 'd' && listdiv == 's'">
            <div
                class="tableframe"
                v-for="(d, ix) in stspattern"
                :key="ix"
                :id="'ds' + ix"
            >
                <AreceivableTable
                    listdiv="deposit"
                    :caption="formatCaption(d)"
                    :fiscalyear="fiscalyear.key"
                    :refreshcount="refreshCount"
                    :params="{ depdiv: dpstatus }"
                    :orderstatus="d"
                />
            </div>
        </div>
        <div class="body" v-if="funcdiv == 'd' && listdiv == 'p'">
            <div
                class="tableframe"
                v-for="(d, ix) in stspattern"
                :key="ix"
                :id="'dp' + ix"
            >
                <AreceivableTable
                    listdiv="payment"
                    :caption="formatCaption(d)"
                    :fiscalyear="fiscalyear.key"
                    :refreshcount="refreshCount"
                    :params="{ pmtdiv: dpstatus }"
                    :orderstatus="d"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AreceivableTable from "@/views/areceivable/AreceivableTable.vue";
import OpSelectFiscalyear from "@/components/OpSelectFiscalyear.vue";
import OpRadioGroup from "@/components/OpRadioGroup.vue";
import OpMultiSelector from "@/components/OpMultiSelector.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
import DateUtils from "@/DateUtils";

export default {
    name: "Areceivable",
    components: {
        AreceivableTable,
        OpSelectFiscalyear,
        OpRadioGroup,
        IconBase,
        IconDownload,
        OpMultiSelector
    },
    props: {},
    data: function() {
        let comp = this.getCompany();
        let fh = DateUtils.getFiscalYearAndHalfsignToday(comp);
        // let dts = DateUtils.getFiscalRangeDate(comp, fh.fiscalyear, 0);
        // let today = new Date();
        return {
            fiscalyear: { key: fh.fiscalyear },
            // startdate: dts.start,
            // enddate: dts.end,
            listdiv: "s",
            funcdiv: "o",
            refreshCount: 1,
            dpstatus: [],
            stspattern: [
                ["A", "B"],
                ["A", "B", "C"],
                ["A", "B", "C", "D"]
            ],
            depdivlist: [
                { key: "0", name: "未入金" },
                { key: "1", name: "入金済" }
            ],
            pmtdivlist: [
                { key: "0", name: "未払い" },
                { key: "1", name: "支払済" }
            ]
        };
    },
    computed: {
        dpstatuslist: function() {
            let ret = [];
            if (this.funcdiv == "d") {
                if (this.listdiv == "s") {
                    ret = this.depdivlist;
                } else {
                    ret = this.pmtdivlist;
                }
            }
            return ret;
        }
    },
    watch: {
        dpstatus: function() {
            this.refreshCount++;
        }
    },
    methods: {
        formatCaption: function(sts, podiv) {
            let ret = "";
            let y = this.fiscalyear.key;
            if (!y) y = this.fiscalyear;
            let ey = this.getCompany().establishment_year;
            ret += DateUtils.formatFiscalYear(y, ey);

            ret += " ";
            ret += this.listdiv == "s" ? "売掛" : "買掛";

            ret += " ";
            if (this.funcdiv == "o") {
                ret += "計上";
            } else {
                ret += this.listdiv == "s" ? "回収" : "支払";
            }

            let sss = "";
            for (let item of sts) {
                sss += "," + item;
            }
            if (sss != "") {
                sss = sss.substring(1);
            }
            ret += " " + sss;

            if (podiv) {
                let div = this.divs["po"][podiv];
                if (div != null) {
                    ret += " ( " + div.contentlong + " ) ";
                }
            }

            if (this.funcdiv == "d") {
                let dps = "";

                for (let item of this.dpstatuslist) {
                    for (let dp of this.dpstatus) {
                        if (item["key"] == dp) {
                            dps += " + " + item["name"] + " ";
                            break;
                        }
                    }
                }
                if (dps == "") {
                    for (let item of this.dpstatuslist) {
                        dps += "+ " + item["name"] + " ";
                    }
                }
                ret += " 　　(" + dps.substring(2) + ")";
            }

            return ret;
        },
        formatlink: function(sts, podiv) {
            let ret = "";
            if (podiv) {
                let div = this.divs["po"][podiv];
                if (div != null) {
                    ret += " ( " + div.contentlong + " ) ";
                }
            }

            let sss = "";
            for (let item of sts) {
                sss += "" + item;
            }

            ret += sss;

            return ret;
        },
        getPodivName: function(podiv) {
            let ret = "";
            if (podiv) {
                let div = this.divs["po"][podiv];
                if (div != null) {
                    ret += div.contentlong;
                }
            }
            return ret;
        },
        changedYear: function() {
            // let dts = DateUtils.getFiscalRangeDate(
            //     this.getCompany(),
            //     year.key,
            //     0
            // );
            // this.startdate = dts.start;
            // this.enddate = dts.end;
            this.refreshCount++;
        },
        download: function() {
            // let params = this.makeParams();

            let filename = "";
            let utype = 0;
            if (this.listdiv == "s") {
                filename += "売掛";
                utype += 0;
            } else {
                filename += "買掛";
                utype += 1;
            }

            if (this.funcdiv == "o") {
                filename += "計上";
            } else {
                filename += this.listdiv == "s" ? "回収" : "支払";
                utype += 2;
            }
            filename += "-" + this.fiscalyear.key + "年度";

            let sts = "";
            for (let s of this.stspattern) {
                sts += ":" + s;
            }
            if (sts != "") {
                sts = sts.substring(1);
            }

            let params = {
                sts: sts
            };

            let u3 = "";
            switch (utype) {
                case 1:
                    u3 = "delivery";
                    break;
                case 2:
                    u3 = "deposit";
                    params["depdiv"] = this.dpstatus;
                    break;
                case 3:
                    u3 = "payment";
                    params["pmtdiv"] = this.dpstatus;
                    break;
                default:
                    u3 = "acceptance";
                    break;
            }

            if (this.funcdiv == "d") {
                let dps = "";

                for (let item of this.dpstatuslist) {
                    for (let dp of this.dpstatus) {
                        if (item["key"] == dp) {
                            dps += "+" + item["name"];
                            break;
                        }
                    }
                }
                if (dps == "") {
                    for (let item of this.dpstatuslist) {
                        dps += "+" + item["name"];
                    }
                }
                filename += "(" + dps.substring(1) + ")";
            }

            filename += "_" + DateUtils.toString8();
            filename += ".xlsx";

            this.downloadData(
                this.makeurl(
                    "areceivable",
                    "xlsx",
                    u3,
                    this.fiscalyear.key,
                    params
                ),
                filename
            );
        }
    },
    created: function() {
        this.loaddivs("po", "pmt", "dep");
    }
};
</script>

<style scoped>
/* .control-box {
    position: sticky;
    bottom: 0;
    border-top: 1px solid #999;
    padding: 10px 0;
    margin-top: 10px;
    text-align: center;
} */
.anchors {
    font-size: 0.8rem;
    padding: 5px;
    text-align: center;
}
.tableanch {
    padding: 0 10px 0 0;
    display: inline-block;
}

.arp .header1 {
    height: 80px;
}

.arp .header2 {
    display: flex;
    justify-items: center;
    justify-content: center;

    width: 100%;
    text-align: center;
    vertical-align: top;
    z-index: 0;
    padding: 0;
}
.arp {
    position: relative;
    padding-left: 10px;
}
.arp .body {
    position: relative;

    padding: 60px 0 0 0;
    z-index: 0;
    background-color: var(--main-context-bg-color);
    margin: 0;
}
.arp .tableframe {
    position: relative;
    padding: 10px 0;
    /* overflow-x: scroll; */
    /* height: calc(100vh - 160px); */
    /* overflow-y: auto; */
}
.tableframe {
    text-align: center;
}
.achievement-rate {
    border-right-style: double;
}
.dept2 {
    border-left-style: double;
    border-left-width: 3px;
}
.soposts {
    padding: 0px 10px;
}
.soposts > div {
    padding: 0px 0px;
    /* transform: scale(0.75); */
}

.logcondition > div {
    padding: 0px 10px;
    display: inline-block;
}
.control-box {
    position: sticky;
    bottom: 0px;
    z-index: 1;
    display: grid;

    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > *:first-child {
    justify-self: left;
}
.control-box > *:last-child {
    justify-self: right;
}

.page-header {
    position: fixed;
    left: 0px;
    width: calc(100vw - 10px);
}

/* --- */
.compareSetting {
    min-height: 300px;
}
.compareSetting > fieldset {
    margin: 0 0.5rem 1rem 1rem;
    padding: 0.5rem 0 1rem 2rem;
}
.compareSetting > fieldset > legend {
    margin-left: -3.25rem;
    padding: 0.5rem;
}
/* --- */

@media (max-width: 1199px) {
    .mpc {
        display: inherit;
    }
    .mpc-inline-block {
        display: inline-block;
    }
    .xpc {
        display: none;
    }
}
@media (min-width: 1200px) {
    .mpc {
        display: none;
    }
    .xpc {
        display: inherit;
    }
}
</style>
