<template>
    <div>
        <OpSelectArray
            v-model="dataModel"
            :required="required"
            :name="name"
            :id="id"
            :label="label"
            :modelList="getFiscalyearList()"
            maxwidth="15rem"
            @changed-valid="changedValid"
            ref="input"
            nosudgest
            :hideheader="hideheader"
        />
    </div>
</template>

<script>
import OpSelectArray from "@/components/OpSelectArray.vue";

export default {
    name: "OpSelectFiscalyear",
    model: {
        prop: "inputModel",
        event: "input"
    },
    props: {
        inputModel: [Object, String, Number],
        required: Boolean,
        name: String,
        id: String,
        label: String,
        company: Object,
        hideheader: Boolean,
        offsetyear: { type: Number, default: 2 }
    },
    components: {
        OpSelectArray
    },
    data: function() {
        return {
            dataModel: this.initDataModel(this.inputModel),
            modelChange: false
        };
    },
    watch: {
        dataModel: function(value) {
            if (this.modelChange) {
                this.modelChange = false;
                return;
            }
            this.$emit("input", value);
        },
        inputModel: function(val) {
            this.modelChange = true;
            this.dataModel = { ...val };
        }
    },
    methods: {
        initDataModel: function(model) {
            let ret = model;
            if (model) {
                let fy = null;
                if (typeof model != "object") {
                    fy = model;
                } else if (typeof model.value != "string") {
                    fy = model.key;
                }
                if (fy != null) {
                    if (fy == "string") {
                        fy = parseInt(model);
                    }
                    let esy = this.company.establishment_year;
                    ret = this.makeFiscalYearObject(fy, esy);
                }
            }
            return ret;
        },
        getFiscalyearList: function() {
            // if (this.fiscalyears) {
            //     return this.fiscalyears;
            // }
            let ret = new Array();

            let now = new Date();
            let ny = now.getFullYear();
            let esy = this.company.establishment_year;
            let mn = esy;

            let mx = ny + this.offsetyear;
            for (let i = mx; i >= mn; i--) {
                ret.push(this.makeFiscalYearObject(i, esy));
            }

            return ret;
        },
        makeFiscalYearObject: function(year, startyear) {
            return {
                key: year,
                value: year + "年度 （第" + (year - startyear + 1) + "期）"
            };
        },
        changedValid: function(name, flag) {
            this.$emit("changed-valid", name, flag);
        }
    },
    created: async function() {
        this.loadbanklist();
    }
};
</script>
