<template>
    <div>
        <OpSelect
            v-model="dataModel"
            :suggest="suggest"
            :suggestValue="suggestValue"
            :suggestKey="suggestKey"
            :required="required"
            :name="name"
            :id="id"
            :label="label"
            :maxwidth="maxwidth"
            :hideheader="hideheader"
            @changed-valid="changedValid"
            ref="input"
        />
    </div>
</template>

<script>
export default {
    name: "OpSelectArray",
    model: {
        prop: "inputModel",
        event: "input"
    },
    props: {
        inputModel: Object,
        required: Boolean,
        name: String,
        id: String,
        label: String,
        maxwidth: String,
        modelList: Array,
        nosudgest: Boolean,
        hideheader: Boolean
    },
    data: function() {
        return {
            dataModel: this.inputModel,
            modelChange: false
        };
    },
    watch: {
        dataModel: function(value) {
            if (this.modelChange) {
                this.modelChange = false;
                return;
            }
            this.$emit("input", value);
        },
        inputModel: function(val) {
            this.modelChange = true;
            this.dataModel = { ...val };
        }
    },
    methods: {
        focus: function() {
            this.$refs.input.focus();
        },
        suggest: function(query, result) {
            let ret = null;
            if (this.nosudgest) {
                result.length = 0;
                let mx = this.modelList.length;
                for (let i = 0; i < mx; i++) {
                    let d = this.modelList[i];
                    result.push(d);
                    if (
                        ret == null &&
                        query != undefined &&
                        query != "" &&
                        this.matched(d.value, query)
                    ) {
                        ret = i;
                    }
                }
                return ret;
            } else {
                result.length = 0;
                for (const d of this.modelList) {
                    if (
                        query == undefined ||
                        query == "" ||
                        this.matched(d.value, query)
                    ) {
                        result.push(d);
                    }
                }
            }
        },
        matched: function(value, query) {
            let ret = false;
            let qs = query.split(" ");
            for (let q of qs) {
                ret = this.includeVal(value, q);
                if (!ret) {
                    break;
                }
            }
            return ret;
        },
        includeVal: function(val, q) {
            let ret = false;
            if (val) {
                val = val.replace(/\s/g, "");
                ret = val.includes(q);
            }
            return ret;
        },
        suggestValue: function(obj) {
            let ret = "";
            if (obj) {
                ret = obj.value;
            }
            return ret;
        },
        suggestKey: function(obj) {
            let ret = "";
            if (obj) {
                ret = obj.key;
            }
            return ret;
        },
        changedValid: function(name, flag) {
            this.$emit("changed-valid", name, flag);
        }
    }
    // created: async function() {
    //     this.loadbanklist();
    // }
};
</script>
