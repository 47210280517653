<template>
    <div>
        <div class="loader" v-if="loading" key="loading">Now loading...</div>
        <transition name="loading" mode="out-in">
            <div class="arptable" v-if="!loading" key="datatable">
                <transition name="strans" mode="out-in">
                    <div v-if="!updating">
                        <div class="tablecaption">
                            <span class="tablecaptionvalue">{{ caption }}</span>
                        </div>
                        <table class="salestarget">
                            <thead>
                                <tr>
                                    <th>取引先</th>
                                    <th v-for="(d, ix) in months" :key="ix">
                                        <span class="year"
                                            >{{ d.substring(0, 4) }} 年</span
                                        >
                                        <span class="month"
                                            >{{ formatYm(d) }} 月</span
                                        >
                                    </th>
                                    <th class="coltotal">合計</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(d, ix) in datalist" :key="ix">
                                    <th class="customer_name">
                                        <div
                                            class="code"
                                            style="font-size:0.5rem;"
                                        >
                                            {{ d.customer_code }}
                                        </div>
                                        {{ d.customer_name_long }}
                                    </th>
                                    <td
                                        v-for="(m, mi) in months"
                                        :key="mi"
                                        class="amount"
                                    >
                                        {{ getAmount(d, "m_" + m) | amount }}
                                    </td>
                                    <td class="amount coltotal">
                                        {{
                                            getAmount(d, "total_amount")
                                                | amount
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th></th>
                                    <td
                                        v-for="(m, mi) in months"
                                        :key="mi"
                                        class="amount"
                                    >
                                        {{ totals["m_" + m] | amount }}
                                    </td>
                                    <td class="amount coltotal">
                                        {{
                                            getAmount(totals, "total_amount")
                                                | amount
                                        }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
// import DateUtils from "@/DateUtils";

export default {
    name: "AreceivableTable",
    props: {
        caption: String,
        fiscalyear: [String, Number],
        // startdate: Date,
        // enddate: Date,
        refreshcount: Number,
        listdiv: String,
        params: Object,
        orderstatus: Array
    },
    data: function() {
        return {
            months: [],
            datalist: [],
            totals: {},
            loading: true,
            updating: false,
            datakeys: []
        };
    },
    watch: {
        refreshcount: async function() {
            this.loaddata();
        },
        listdiv: async function() {
            this.loaddata();
        }
    },
    created: async function() {
        if (this.fiscalyear) {
            this.loaddata();
        }
        // this.depts = Array.from(this.deptlist);
    },
    methods: {
        // initMonths: function() {
        //     let smonth = this.startdate.getMonth();
        //     let emonth = this.enddate.getMonth();
        //     let syear = this.startdate.getFullYear();
        //     let eyear = this.enddate.getFullYear();

        //     let dyear = eyear - syear;
        //     let dmonth = emonth - smonth;
        //     if (dmonth < 0) {
        //         dmonth = 12 + dmonth;
        //         dyear--;
        //     }
        //     let mcount = dyear * 12 + dmonth + 1;

        //     let ms = [];
        //     let sdate = new Date(this.startdate.getTime());
        //     sdate.setDate(1);
        //     for (let i = 0; i < mcount; i++) {
        //         let sd = new Date(sdate.getTime());
        //         sd.setMonth(sd.getMonth() + i);
        //         ms.push(DateUtils.toYm6(sd));
        //     }
        //     return ms;
        // },
        formatYm: function(ym) {
            let ret = ym.substring(4);
            if (ret.startsWith("0")) {
                ret = ret.substring(1);
            }
            return ret;
        },
        dispSts: function() {
            let ret = "";
            for (let item of this.orderstatus) {
                ret += "," + item;
            }
            if (ret != "") {
                ret = ret.substring(1);
            }
            return ret;
        },
        loaddata: async function() {
            this.loading = true;
            // this.months = this.initMonths();
            let params = {
                // period: DateUtils.toRequestRangeDate(
                //     this.startdate,
                //     this.enddate
                // )
            };
            this.datalist = [];
            params["sts"] = this.orderstatus;
            if (this.params) {
                for (let k in this.params) {
                    params[k] = this.params[k];
                }
            }
            return this.readDataToArray(
                this.makeurl(
                    "areceivable",
                    this.listdiv,
                    this.fiscalyear,
                    params
                ),
                (list, keys) => {
                    this.months = [];
                    for (let key of keys) {
                        if (key.startsWith("m_")) {
                            this.months.push(key.substring(2));
                        }
                    }

                    this.datalist = list;

                    let totals = {};
                    for (let m of this.months) {
                        let c = "m_" + m;
                        totals[c] = 0;
                    }
                    totals["total_amount"] = 0;
                    for (let d of list) {
                        for (let m of this.months) {
                            let c = "m_" + m;
                            totals[c] += this.getAmount(d, c);
                        }
                        totals["total_amount"] += this.getAmount(
                            d,
                            "total_amount"
                        );
                    }
                    this.totals = totals;
                    this.loading = false;
                }
            );
        },

        getAmount: function(row, colname) {
            let ret = 0;
            if (row && row[colname]) {
                ret = row[colname];
                ret = new Number(ret);
            }
            return ret;
        },
        download: function() {
            let params = this.makeParams();

            let filename = "売上達成率";
            for (const p in params) {
                let v = params[p];
                filename += "_";
                if (v) {
                    if (v instanceof Array) {
                        for (let c of v) {
                            filename += c;
                        }
                    } else {
                        filename += v;
                    }
                }
            }
            filename += ".xlsx";
            this.downloadData(this.makeurl("stgt", "xlsx", params), filename);
        }
    },
    updated: function() {
        this.$nextTick(() => (this.updating = false));
    }
};
</script>

<style scoped>
.arptable {
    position: relative;
    display: inline-block;
}

.arptable table {
    position: relative;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
}
.arptable table tr > * {
    border-style: solid none none solid;
    border-width: 1px;
    background-color: var(--main-context-bg-color);
}
.arptable table td {
    text-align: right;
    font-size: 1rem;
    vertical-align: middle;
}
.arptable table th {
    text-align: right;
    font-size: 0.9rem;
    vertical-align: middle;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
    /* filter: grayscale(50%) brightness(98%); */
}
.arptable table tbody tr.branchDept {
    /* filter: brightness(95%); */
}
.arptable table tbody tr.branchDept > * {
    border-top-style: dashed;
    /* font-size: 75%; */
    /* letter-spacing: 1.75px; */
    filter: hue-rotate(0deg) grayscale(50%) brightness(90%);
}
.arptable table tbody th > .child::before {
    content: " - ";
    padding-right: 0.5rem;
    opacity: 0.25;
}
/* .arptable table tbody tr:last-child th > .child::before {
    content: "└";
} */
/* .arptable table tbody tr.branchDept > * {
    border-top-style: dotted;
    border-bottom-style: dotted;
} */
.tablecaption {
    display: block;
    position: sticky;
    top: 70px;
    left: 0;
    background-color: var(--main-context-bg-color);
    z-index: 4;
    padding: 10px;
    text-align: left;
    margin: 0;
    width: 100%; /* calc(100vw - 18px); */
}
.tablecaptionvalue {
    position: sticky;
    left: 10px;
}

.arptable table thead tr:nth-child(1) > * {
    position: sticky;
    top: 106px;
    /* background-color: #eee; */
    z-index: 2;
}
.arptable table thead tr:nth-child(2) > * {
    position: sticky;
    top: calc(106px + 23px);
    /* background-color: #eee; */
    z-index: 2;
}
.arptable table thead tr > th {
    border-bottom-style: solid;
    vertical-align: bottom;
    text-align: center;
}
.arptable table thead tr:first-child > th.detail {
    border-bottom-style: none;
    border-bottom-width: 0;
}

.arptable thead tr:first-child > *:first-child {
    position: sticky;
    top: 106px;
    left: 0;
    /* background-color: #eee; */
    border-right-style: solid;
    border-right-width: 1px;
    z-index: 3;
}
.arptable thead tr:first-child > *:last-child {
    border-right-style: solid;
    border-right-width: 1px;
    /* position: sticky;
    right: 0; */
}
.arptable table tbody tr.branchDept > *:first-child {
    /* background-color: #edd; */
}
.arptable tbody tr > *:first-child {
    position: sticky;
    left: 0;
    border-right-width: 1px;
    border-right-style: solid;
    /* background-color: #eee; */
    text-align: left;
    z-index: 2;
}

.arptable tbody tr > *:last-child {
    /* position: sticky;
    right: 0; */
    border-right-width: 1px;
    border-right-style: solid;
}

.arptable tbody tr:nth-child(even) > td {
    /* filter: contrast(95%); */
    /* background-color: hsl(333, 100%, 97%); */
}

.arptable tbody tr:nth-child(2n + 1) > td {
    background: var(--odd-bg-color);
}

.arptable table .coltotal {
    border-left-style: double;
    border-left-width: 4px;
    position: sticky;
    right: 0;
}

.arptable table tfoot tr > * {
    position: sticky;
    bottom: 0;
    /* background-color: #eee; */
    border-top-style: double;
    border-top-width: 6px;
    z-index: 2;
}
.arptable tfoot tr > *:first-child {
    position: sticky;
    bottom: 0;
    left: 0;
    border-right-width: 1px;
    border-right-style: solid;
    /* background-color: #eee; */
    z-index: 3;
}
.arptable tfoot tr > * {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
.arptable tfoot tr > *:last-child {
    border-right-width: 1px;
    border-right-style: solid;
    /* position: sticky;
    right: 0; */
}
.arptable tfoot tr td {
    /* filter: grayscale(80%) brightness(98%); */
}
.arptable tfoot tr td.detail {
    /* filter: brightness(95%); */
}

.arptable .detail {
    /* font-size: 0.75rem; */
    /* opacity: 0.65; */
    /* filter: brightness(95%); */
    /* filter: brightness(95%); */
    font-size: 75%;
    letter-spacing: 1.25px;
}
.arptable th.detail {
    /* opacity: 0.65; */
    /* filter: brightness(95%); */
    /* filter: grayscale(10%) brightness(95%); */
    font-size: 75%;
}

/* .arptable .achievement-rate {
    border-right-style: double;
    border-right-width: 6px;
} */
.arptable .achievement-rate + * {
    /* border-left-style: double;
    border-left-width: 6px; */
}

.arptable .amount {
    min-width: 8em;
    width: 8em;
}
.arptable .rate {
    min-width: 4em;
    font-size: 1.1em;
}
.arptable .value {
    height: 1rem;
}
.arptable .logdata {
    height: 1rem;
    opacity: 0.5;
}
.arptable .logdiff {
    height: 1rem;
    opacity: 0.5;
}

/* --- */
.year {
    display: block;
    font-size: 75%;
}
/* --- */

.strans-enter-active,
.strans-leave-active {
    /* animation: sizeScale 0.5s ease reverse; */
    transition: 0.25s;
}

.strans-enter,
.strans-leave-to {
    /* animation: sizeScale ease 0.5s; */
    opacity: 0;
}
@keyframes sizeScale {
    100% {
        /* width: 0px; */
        opacity: 0;
    }
}
/* ------------ */
.controlbox {
    padding: 10px;
}
</style>
